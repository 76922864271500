import { Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useState } from 'react';
// import './LoginDetail.styles.scss'
import { LoginDetailProps } from '../../../../../types/SharedTypes';
import styles from './LoginDetail.module.css';
import classNames from 'classnames';
// import {hideSignUpPopup, showLoginPopup} from '../../../../../redux/slices/LoginSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { registerUser } from '../../../../../redux/thunk/dataThunk';
import { useNavigate } from 'react-router-dom';

function LoginDetail(props: LoginDetailProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [ email, setEmail ] = useState('');
	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ isEmailValid, setIsEmailValid ] = useState(false);
	const [ errorMessage, setErrorMessage ] = React.useState('');

	const validateEmail = (email: string): boolean => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setIsEmailValid(validateEmail(e.target.value)); // Set validity state
	};

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value);
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value);
	};

	const handleLoginClicked = () => {
		// dispatch(hideSignUpPopup());
		// dispatch(showLoginPopup());
	};

	const continueClicked = (event: any) => {
		event.preventDefault();
		console.log('continue clicked');
		dispatch(
			registerUser(
				email,
				firstName,
				lastName,
				() => {
					console.log('passed function called');
					props.handleNext(email, firstName, lastName);
				},
				(message) => setErrorMessage(message)
			)
		);
	};

	return (
		<div className={classNames(styles['login-detail-container'])}>
			<FormControl
				sx={{
					m: 1,
					width: '100%'
				}}
			>
				<TextField
					className={classNames(styles['email-input'])}
					id="outlined-email"
					size="small"
					fullWidth
					label="Email"
					variant="outlined"
					value={email}
					onChange={handleEmailChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircleIcon />
							</InputAdornment>
						)
					}}
				/>{' '}
				{!isEmailValid &&
				email !== '' && (
					<div
						style={{
							color: 'red',
							marginTop: '5px'
						}}
					>
						Email is not valid
					</div>
				)}
			</FormControl>

			<FormControl
				sx={{
					m: 1,
					width: '100%'
				}}
			>
				<TextField
					id="outlined-first-name"
					size="small"
					fullWidth
					label="First Name"
					variant="outlined"
					value={firstName}
					onChange={handleFirstNameChange}
				/>
			</FormControl>

			<FormControl
				sx={{
					m: 1,
					width: '100%'
				}}
			>
				<TextField
					id="outlined-last-name"
					size="small"
					fullWidth
					label="Last Name"
					variant="outlined"
					value={lastName}
					onChange={handleLastNameChange}
				/>
			</FormControl>

			<Button
				fullWidth
				onClick={continueClicked}
				variant="contained"
				color="primary"
				className={classNames(styles['ConfirmRegistration_submitButton'])}
			>
				Continue
			</Button>

			<div style={{ color: 'red' }}>{errorMessage}</div>
			<div>
				Already a Customer?
				<span
					onClick={handleLoginClicked}
					style={{
						cursor: 'pointer',
						textDecoration: 'underline'
					}}
				>
					Login
				</span>
			</div>
		</div>
	);
}

export default LoginDetail;
