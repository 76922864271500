import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './Login.module.css'; // Consider renaming the CSS file too
import { Button, Link, TextField } from '@mui/material';
import { useAppDispatch } from '../../redux/hooks';
import { login } from '../../redux/thunk/dataThunk';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const authToken = useSelector((state : RootState) => state.session.session_token);

    const textFieldSx = {
        width: '28.4%',
        '& label': {
          color: 'white',
        },
        '& label.Mui-focused': {
          color: 'white', 
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', 
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', 
          },
          '& input': {
            color: 'white', 
          },
        },
      };



    const handleLogin = async (event: any) => {
      event.preventDefault();
      const result = await dispatch(login(email, password));
    };

    if(authToken){
      navigate('/');
    }

  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>Login</h3>
            <div className={styles.subHeader}>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)}
                    fullWidth margin="normal" sx={textFieldSx} 
                />
                <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                    fullWidth margin="normal" sx={textFieldSx}
                />
                <Button 
                    variant="outlined" 
                    style={{color:'white', backgroundColor:'#1a2035', marginTop:'2%', padding:'0.7% 2.5%', border:'1px solid #1a2035'}}
                    onClick={handleLogin}
                >
                    Log In
                </Button>
                <div style={{marginTop: '1rem'}}>
                        <Link component="button" sx={{marginRight:'15px'}} variant="body2" onClick={() => navigate('/forgot-password')} style={{color: 'white'}}>
                            Forgot Password?
                        </Link>
                        
                        <Link component="button" variant="body2" onClick={() => navigate('/register')} style={{color: 'white'}}>
                            Register
                        </Link>
                    </div>
            </div>
        </div>
    </>
  )
}

export default Login;
