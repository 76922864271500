import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './InternetPayout.module.css';
import { Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material';
import 'react-international-phone/style.css';
import { width } from '@mui/system';

function InternetPayout() {
    const [requestCode, setRequestCode] = useState('');
    const [receiptCode, setReceiptCode] = useState('');
    const [forcePayoutText, setForcePayoutText] = useState('');

      const textFieldSx = {
        width: '28.4%',
        '& label': {
          color: 'white',
        },
        '& label.Mui-focused': {
          color: 'white', 
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', 
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', 
          },
          '& input': {
            color: 'white', 
          },
        },
      };

      const textAreaSx = {
        width: '100%',
        '& label': {
          color: 'white',
        },
        '& label.Mui-focused': {
          color: 'white', 
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', 
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', 
          },
          '& input': {
            color: 'white', 
          },
        },
      };


  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>Internet Payout</h3>
            <div className={styles.subHeader}>
                
                    <TextField label="Request Code" value={requestCode} onChange={(e) => setRequestCode(e.target.value)}
                        fullWidth margin="normal" sx={textFieldSx} 
                    />
                    <div style={{width:'28%', padding:'1% 0%'}}>Amount: 0</div>
                    <div style={{width:'28%', padding:'1% 0%'}}>Charge: 0</div>
                    <div style={{width:'28%', padding:'1% 0%'}}>Total: 0</div>
                    <TextField label="Receipt Code" value={receiptCode} onChange={(e) => setReceiptCode(e.target.value)}
                        fullWidth margin="normal" sx={textFieldSx}
                    />    
                    <div className={styles.container}>
                    <Card sx={{ maxWidth: 360 }}>
                      
                      <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        To make a payout without the receipt, enter a message of atleast 10 characters stating the reason and press the forced payout button.
                      </Typography>
                      </CardContent>
                    </Card>
                    <div style={{width:'29%', marginLeft:'5%'}}>
                    <TextField label="Force Payout" value={forcePayoutText}  onChange={(e) => setForcePayoutText(e.target.value)}
                        fullWidth margin="normal" sx={textAreaSx}
                    /> 
                    </div>
                       
                            
                    </div>   
                    
                    <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', marginTop:'2%', padding:'0.7% 2.5%', border:'1px solid #1a2035'}}>Payout</Button>
            </div>
        </div>
    </>

  )
}

export default InternetPayout