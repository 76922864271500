// DetailContainer.tsx
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Header/Header';
import styles from './DetailContainer.module.css'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { CSSProperties } from 'react';

const DetailContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const rows = [
    { code:'0132', fixture: 'ManUtd vs Arsenal 1X2', home: 'ManUtd 1.52', draw: 'Draw 2.0', away: 'Arsenal 2.50' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Over/Under 2.5', home: 'Over 1.85', draw: 'N/A', away: 'Under 1.95' },
    { code:'0132', fixture: 'ManUtd vs Arsenal BTTS', home: 'Yes 1.70', draw: 'N/A', away: 'No 2.10' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Asian Handicap -1', home: 'ManUtd 2.05', draw: 'N/A', away: 'Arsenal 1.75' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Correct Score 2-1', home: 'Yes 8.50', draw: 'N/A', away: 'No 1.07' },
    { code:'0132', fixture: 'ManUtd vs Arsenal First Goal Scorer', home: 'Rashford 5.00', draw: 'N/A', away: 'Aubameyang 6.00' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Half-Time/Full-Time', home: 'ManUtd/ManUtd 3.40', draw: 'Draw/Draw 4.50', away: 'Arsenal/Arsenal 5.50' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Total Corners Over/Under', home: 'Over 9.5 1.75', draw: 'N/A', away: 'Under 9.5 2.05' },
    { code:'0132', fixture: 'ManUtd vs Arsenal Total Cards Over/Under', home: 'Over 3.5 2.00', draw: 'N/A', away: 'Under 3.5 1.80' },
    { code:'0132', fixture: 'ManUtd vs Arsenal To Win To Nil', home: 'ManUtd 3.60', draw: 'N/A', away: 'Arsenal 4.75' },
  ];

  const headingStyle: CSSProperties = {
    backgroundColor: '#1a2035', 
    color: 'white',
    textAlign: 'center'
  };

  const paddingLeft: CSSProperties = {
    paddingLeft:'6%'
  };

  return (
    <>
        
        <Header/>
        <div className={styles.DetailContainer}>

            
            <h4> <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon style={{ color: 'white' }}/>
            </IconButton>Select Extras</h4>
            
            <TableContainer component={Paper} style={{ maxHeight: 900 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headingStyle} align="left">Code</TableCell>
                            <TableCell style={headingStyle} align="left">Fixture/Offer Type</TableCell>
                            <TableCell style={headingStyle} align="center">Home</TableCell>
                            <TableCell style={headingStyle} align="center">Draw</TableCell>
                            <TableCell style={headingStyle} align="center">Away</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left" component="th" scope="row">
                                {row.code}
                            </TableCell>
                            <TableCell style={paddingLeft} align="left" component="th" scope="row">
                                {row.fixture}
                            </TableCell>
                            <TableCell style={paddingLeft} align="left">
                                <Checkbox /> {row.home} 
                            </TableCell>
                            <TableCell style={paddingLeft} align="left">
                                <Checkbox /> {row.draw} 
                            </TableCell>
                            <TableCell style={paddingLeft} align="left">
                                <Checkbox /> {row.away} 
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </>
  );
};

export default DetailContainer;
