import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './NewClientAccount.module.css';
import { Button, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './NewClientAccount.css';



function NewClientAccount() {
    const [phone, setPhone] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');

      const textFieldSx = {
        width: '100%',
        '& label': {
          color: 'white', // Default label color
        },
        '& label.Mui-focused': {
          color: 'white', // Label color when the input is focused
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', // Border color
          },
          '&:hover fieldset': {
            borderColor: 'white', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Border color when focused
          },
          '& input': {
            color: 'white', // Input text color
          },
        },
      };


  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>Open Client Account</h3>
            <div className={styles.container}>
                <Card sx={{ maxWidth: 360 }}>
                  <CardHeader title="Options" />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    Options.
                    <br/>
                    <br/>
                    1. Enter mobile nunber or Email/Username of the customer.
                      The mobile number becomes the Customers Username unless you specify an optional Username.
                      The Email is optional.
                    <br/>
                    <br/>
                    2. If you don't enter Mobile Number:
                      Both Email and Username are required.
                    </Typography>
                  </CardContent>
                </Card>
              <div className={styles.subHeader}>
                <PhoneInput defaultCountry="ua" value={phone} onChange={(phone) => setPhone(phone)}/>
                <TextField label="Email Address" type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                    margin="normal" sx={textFieldSx} 
                />
                <TextField label="User Name"  value={userName} onChange={(e) => setUserName(e.target.value)}
                    fullWidth margin="normal" sx={textFieldSx}
                />
                <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', marginTop:'10%', padding:'2.7% 4.5%', border:'1px solid #1a2035'}}>Create New Account</Button>
              </div>
            </div>
        </div>
    </>

  )
}

export default NewClientAccount