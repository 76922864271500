import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockIcon from '@mui/icons-material/Lock';
import { useLocation } from 'react-router-dom';
// import { confirmUser } from "../../../redux/thunk/dataThunk";
import { useNavigate } from 'react-router-dom';
import { AppBar, createTheme } from "@mui/material";
import classNames from 'classnames';
import styles from './ConfirmRegistration.module.css';
import { ThemeProvider } from "@emotion/react";
import { useAppDispatch } from "../../redux/hooks";
import { confirmUserRegistration } from "../../redux/thunk/dataThunk";
import Header from "../Header/Header";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        SIS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const darkTheme = createTheme({
  palette: {
      mode: 'dark',
      primary: {
          main: '#bb86fc'
      },
      background: {
          default: '#121212',
          paper: '#333333'
      },
      text: {
          primary: '#ffffff',
          secondary: '#eeeeee'
      }
  }
});

const ConfirmRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isPasswordMatch, setIsPasswordMatch] = React.useState(true);


  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [email, setEmail] = useState(queryParams.get('email') || '');
  const [code, setCode] = useState(queryParams.get('code') || '');
  const [errorMessage, setErrorMessage] = React.useState('');

  // const emailParam = queryParams.get('email');
  // const codeParam = queryParams.get('code');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordError(!validatePassword(newPassword));
    setIsPasswordMatch(newPassword === confirmPassword);
};

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword);
};

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); 
    try{
      // if(emailParam && codeParam){
        await dispatch(confirmUserRegistration(email,password,code,()=>{
          navigate('/')
        },(message)=>setErrorMessage(message)));
      //   navigate('/');
      // }
    }
    // () => {
    //   console.log('passed function called')
    //   props.handleNext(email, firstName, lastName)
    // },  (message) => setErrorMessage(message))
    catch(error){
      console.error("Error confirming user:", error);
    }
  };

  return (
    <>
            <ThemeProvider theme={darkTheme}>
            <div style={{
                height: '100vh'
            }}>
              
      <Header/>
      <div className={styles.mainContainer}>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classNames(styles['ConfirmRegistration_container'])}>
        <Avatar className={classNames(styles['ConfirmRegistration_avatar'])}>
          <LockIcon></LockIcon>
        </Avatar>
        <Typography component="h1" variant="h5">
          Email Confirmation
        </Typography>
        <form onSubmit={handleSubmit} noValidate className={classNames(styles['ConfirmRegistration_form'])}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                value={email} 
                required
                inputProps={{ readOnly: !!queryParams.get('email') }}
                onChange={handleEmailChange}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="password"
                value={password || ''} 
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={passwordError ? "Invalid password. Ensure it has at least 8 characters, 1 uppercase, 1 lower, 1 number, and 1 special character." : ""}
                id="password"
                label="password"
                name="password"
                autoComplete="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={confirmPassword || ''} 
                onChange={handleConfirmPasswordChange}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                error={!isPasswordMatch}
                helperText={isPasswordMatch ? '' : 'Passwords do not match'}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                required
                inputProps={{ readOnly: !!queryParams.get('code') }}
                fullWidth
                value={code}
                onChange={handleCodeChange}
                id="code"
                label="Code"
                name="code"
                autoComplete="code"
              />

            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={passwordError || !password || !isPasswordMatch}
            variant="contained"
            color="primary"
            className={classNames(styles['ConfirmRegistration_submitButton'])}
          >
            Confirm Registration
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
      </div>

              
              </div></ThemeProvider>
    
    </>
  );
}


export default ConfirmRegistration;