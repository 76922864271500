// App.tsx
import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Sidebar from './components/sidebar/sidebar';
import Home from './components/Home/Home';
import styles from './theme.module.css';
import DetailContainer from './components/DetailContainer/DetailContainer';
import DepositContainer from './components/DepositContainer/DepositContainer';
import WinContainer from './components/WinContainer/WinContainer';
import InternetPayout from './components/InternetPayout/InternetPayout';
import NewClientAccount from './components/NewClienttAccount/NewClientAccount';
import AccountReport from './components/AccountReport/AccountReport';
import Login from './components/Login/Login';
import useSessionManagement from './components/Session/useSessionManagement';
import ConfirmRegistration from './components/Registration/ConfirmRegistration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword/ResetPassword';
import SetNewPassword from './components/ForgotPassword/ForgotPasswordEmailConformation/ForgotPasswordEmailConformation';
import SignUpOverlay from './components/Overlay/SignupOverlay/SignupOverlay';
import {useAppDispatch} from './redux/hooks';

function App() {
    useSessionManagement();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const excludedPaths = [
        "/register-confirm",
        "/forgot-password",
        "/reset-password",
        "/set-new-password",
        "/login",
        "/register"
    ];
    let showHeaderAndFooter = !excludedPaths.includes(location.pathname);
    return (

        <div className={styles.appContainer}>
            {showHeaderAndFooter && <Sidebar/>}
            <div className={styles.mainContent}>
                <Routes>
                    <Route path="/" element={< Navigate replace to = "/EnterBet" />}/>
                    <Route path="/EnterBet" element={< Home />}/>
                    <Route path="/home/:id" element={< DetailContainer />}/>
                    <Route path="/deposit" element={< DepositContainer />}/>
                    <Route path="/win" element={< WinContainer />}/>
                    <Route path="/InternetPayout" element={< InternetPayout />}/>
                    <Route path="/NewClientAccount" element={< NewClientAccount />}/>
                    <Route path="/AccountReport" element={< AccountReport />}/>
                    <Route path="/Login" element={< Login />}/>
                    <Route path="register-confirm" element={< ConfirmRegistration />}/>
                    <Route path="forgot-password" element={< ForgotPassword />}/>
                    <Route path="set-new-password" element={< ResetPassword />}/>
                    <Route path="reset-password" element={< SetNewPassword />}/>
                    <Route path="register" element={< SignUpOverlay />}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
