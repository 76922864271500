import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import LoginDetail from './SignupDetail/LoginDetails/LoginDetail';
// import { SignupOverlayProps } from '../../../types/SharedTypes';
import {useAppDispatch} from '../../../redux/hooks';
// import { registerUser } from '../../../redux/thunk/dataThunk';
import classNames from 'classnames';
import styles from './SignupOverlay.module.css';
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from '@emotion/react';
import Header from '../../Header/Header';
import {createTheme} from '@mui/material';
// import { hideSignUpPopup } from '../../../redux/slices/LoginSlice';

const steps = ['Login Detail', 'Verification'];

export default function SignUpOverlay() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [activeStep,
        setActiveStep] = React.useState(0);
    const [skipped,
        setSkipped] = React.useState(new Set < number > ());

    const isStepOptional = (step : number) => {
        return step === 1;
    };

    const isStepSkipped = (step : number) => {
        return skipped.has(step);
    };

    const handleRegistered = (email : string, firstname : string, lastName : string) => {
        handleNext();
        // dispatch(registerUser(email,phone));
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function closeSignUpPopup() {
        // dispatch(hideSignUpPopup());
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark', // Switches the theme to dark mode
            primary: {
                main: '#bb86fc'
            },
            background: {
                default: '#121212',
                paper: '#333333'
            },
            text: {
                primary: '#ffffff',
                secondary: '#eeeeee'
            }
        }
    });

    return ( <> <ThemeProvider theme={darkTheme}>
        <div style={{
            height: '100vh'
        }}>
            <Header/>
            <div className={styles.mainContainer}>
                <div className={styles.stepper}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps : {
                                completed?: boolean
                            } = {};
                            const labelProps : {
                                optional?: React.ReactNode;
                            } = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography component={'div'} variant="caption"></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <form>
                        {activeStep === steps.length
                            ? (
                                <React.Fragment>
                                    <Typography
                                        component={'div'}
                                        sx={{
                                        mt: 2,
                                        mb: 1
                                    }}>
                                        All steps completed - you&apos;re Registered, please LogIn
                                    </Typography>
                                    <Box
                                        sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        pt: 2
                                    }}>
                                        <Box
                                            sx={{
                                            flex: '1 1 auto'
                                        }}/>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <Typography
                                        component={'div'}
                                        sx={{
                                        mt: 2,
                                        mb: 1
                                    }}>
                                        {activeStep + 1 === 1
                                            ? <LoginDetail handleNext={handleRegistered}/>
                                            : null}
                                        {activeStep + 1 === 2
                                            ? <> A confirmation code has been sent to your E - mail,
                                        please confirm your registration. < br /> <div className={classNames(styles['login-btn-submit-container'])}>

                                            <Button
                                                fullWidth
                                                onClick={() => navigate('/')}
                                                variant="contained"
                                                color="primary"
                                                className={classNames(styles['ConfirmRegistration_submitButton'])}>
                                                Home
                                            </Button>
                                        </div> </> : null}
                                    </Typography>
                                </React.Fragment>
                            )}
                    </form>
                </div>
            </div>
        </div>
    </ThemeProvider> </>
    );
}