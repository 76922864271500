// Sidebar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import IosShareIcon from '@mui/icons-material/IosShare';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';

function Sidebar() {
  return (
    <div className={styles.sidebar}>
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#1f283e', color:'white' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <NavLink to="/EnterBet" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <ArticleIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Enter Bet" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/deposit" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <AccountBalanceWalletIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Deposit" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/win" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <MonetizationOnIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Win" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/InternetPayout" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <IosShareIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Internet Payout" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/NewClientAccount" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <AccountCircleIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="New Client Account" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/AccountReport" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <AssessmentIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Account Report" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          
        </List>
      </nav>
    </Box>
    </div>
  );
}

export default Sidebar;
