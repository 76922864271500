import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockIcon from '@mui/icons-material/Lock';
import {useLocation} from 'react-router-dom';
// import { setNewPassword } from "../../../../redux/thunk/dataThunk";
import {useNavigate} from 'react-router-dom';
import {AppBar} from "@mui/material";
import classNames from 'classnames';
import styles from './ResetPassword.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch } from "../../../redux/hooks";
import { changePassword } from "../../../redux/thunk/dataThunk";
import Header from "../../Header/Header";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="#">
                SIS
            </Link>{" "} {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Switches the theme to dark mode
    primary: {
      main: '#bb86fc',
    },
    background: {
      default: '#121212',
      paper: '#333333',
    },
    text: {
      primary: '#ffffff',
      secondary: '#eeeeee',
    },
  },
});

const ConfirmRegistration = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const [currentPassword,
      setCurrentPassword] = React.useState('');

    const [newPassword,
        setNewPassword] = React.useState('');
    const [passwordError,
        setPasswordError] = React.useState(false);
    const [confirmNewPassword,
        setConfirmNewPassword] = React.useState('');
    const [isPasswordMatch,
        setIsPasswordMatch] = React.useState(true);

    const {search} = useLocation();
    const queryParams = new URLSearchParams(search);

    const emailParam = queryParams.get('email') || '';
    const codeParam = queryParams.get('code');

    
    const handleOldPasswordChange = (event : React.ChangeEvent < HTMLInputElement >) => {
      setCurrentPassword(event.target.value);
  };

    const handlePasswordChange = (event : React.ChangeEvent < HTMLInputElement >) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);
        setPasswordError(!validatePassword(newPassword));
        setIsPasswordMatch(newPassword === confirmNewPassword);
    };

    const handleConfirmPasswordChange = (event : React.ChangeEvent < HTMLInputElement >) => {
        const newConfirmPassword = event.target.value;
        setConfirmNewPassword(newConfirmPassword);
        setIsPasswordMatch(newPassword === newConfirmPassword);
    };

    const validatePassword = (password : string) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async(event : React.FormEvent < HTMLFormElement >) => {
        event.preventDefault();
        
        dispatch(changePassword(currentPassword,newPassword))
        setNewPassword('');
        setCurrentPassword('');
        setConfirmNewPassword('');
    };

    return ( <> 
    <ThemeProvider theme={darkTheme}>
    <div style={{ height: '100vh' }}>
    <Header/>
    <div className={styles.mainContainer}>
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classNames(styles['ConfirmRegistration_container'])}>
                <Avatar className={classNames(styles['ConfirmRegistration_avatar'])}>
                    <LockIcon></LockIcon>
                </Avatar>
                <Typography component="h1" variant="h5">
                    New Password Confirmation
                </Typography>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classNames(styles['ConfirmRegistration_form'])}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="password"
                                value={currentPassword}
                                data-testid="current-password-input"
                                onChange={handleOldPasswordChange}
                                id="oldPassword"
                                placeholder="Enter current password"
                                label="Old Password"
                                name="oldpassword"
                                autoComplete="oldPassword"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="password"
                                value={newPassword}
                                data-testid="new-password-input"
                                onChange={handlePasswordChange}
                                error={passwordError}
                                helperText={passwordError
                                ? "Invalid password. Ensure it has at least 8 characters, 1 uppercase, 1 lower, 1 n" +
                                    "umber, and 1 special character."
                                : ""}
                                id="password"
                                placeholder="Enter new password"
                                label="New Password"
                                name="password"
                                autoComplete="password"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={confirmNewPassword}
                                data-testid="confirm-password-input"
                                onChange={handleConfirmPasswordChange}
                                name="confirmPassword"
                                label="Confirm New Password"
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm new password"
                                error={!isPasswordMatch}
                                helperText={isPasswordMatch
                                ? ''
                                : 'Passwords do not match'}
                                autoComplete="new-password"/>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        disabled={passwordError || !newPassword || !isPasswordMatch || !currentPassword}
                        variant="contained"
                        color="primary"
                        className={classNames(styles['ConfirmRegistration_submitButton'])}>
                        Change Password
                    </Button>
                </form>
            </div>
            {/* <input type="text" id="password" /> */}
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
        </div>
    </div>
    </ThemeProvider>
 </>
  );
}


export default ConfirmRegistration;