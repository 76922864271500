import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './AccountReport.module.css';
import { Button, TextField } from '@mui/material';
import 'react-international-phone/style.css';
import './AccountReport.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';


function AccountReport() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), // You might want to adjust this
      key: 'selection', // Ensure this is always a string
    }
  ]);



  interface ReportEntry {
    count?: number;
    amount: number;
  }
  
  interface ReportData {
    [key: string]: ReportEntry;
  }

  const reportData: ReportData = {
    bets: { count: 10, amount: 200 },
    tax: { amount: 50 },
    cancelledBets: { count: 2, amount: 40 },
    wins: { count: 5, amount: 150 },
    deposits: { count: 3, amount: 300 },
    withdrawals: { count: 1, amount: 100 },
  };

  const totalAmount = Object.values(reportData).reduce((acc, item) => acc + (item.amount || 0), 0);

  const handleDateChange = (item:any) => {
    const updatedRange = {
      startDate: item.selection.startDate || new Date(),
      endDate: item.selection.endDate || new Date(),
      key: item.selection.key || 'selection', // Provide a default string value
    };
    setState([updatedRange]);
  };


  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>Account Report</h3>
            <div className={styles.subHeader}>
              <div className={styles.dataRangePicker}>
                <DateRangePicker
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell align="right">Count</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.entries(reportData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell align="right">{value.count || 'N/A'}</TableCell>
                        <TableCell align="right">{value.amount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2}>Total</TableCell>
                      <TableCell align="right">{totalAmount}</TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>    
              
            </div>
            <div className={styles.buttonContainer}>
              <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', marginTop:'2%', padding:'0.7% 2.5%', border:'1px solid #1a2035'}}>Print Report</Button>
            </div>
        </div>
    </>

  )
}

export default AccountReport