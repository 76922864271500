import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { CSSProperties } from 'react';
import styles from './MainContainer.module.css';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RightSidebar from '../RightSidebar/RightSidebar';

const MainContainer = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    
    const rows = [
        { code:'0132', fixture: 'ManUtd vs Arsenal 1X2', home: 'ManUtd 1.52', draw: 'Draw 2.0', away: 'Arsenal 2.50' },
        { code:'0132', fixture: 'Liverpool vs Chelsea 1X2', home: 'Liverpool 1.75', draw: 'Draw 3.1', away: 'Chelsea 4.50' },
        { code:'0132', fixture: 'Real Madrid vs Barcelona 1X2', home: 'Real Madrid 1.85', draw: 'Draw 3.5', away: 'Barcelona 3.75' },
        { code:'0132', fixture: 'Bayern vs Dortmund 1X2', home: 'Bayern 1.65', draw: 'Draw 4.0', away: 'Dortmund 5.50' },
        { code:'0132', fixture: 'PSG vs Lyon 1X2', home: 'PSG 1.40', draw: 'Draw 4.5', away: 'Lyon 6.00' },
        { code:'0132', fixture: 'Juventus vs Inter 1X2', home: 'Juventus 2.10', draw: 'Draw 3.2', away: 'Inter 3.30' },
        { code:'0132', fixture: 'AC Milan vs Napoli 1X2', home: 'AC Milan 2.30', draw: 'Draw 3.3', away: 'Napoli 3.10' },
        { code:'0132', fixture: 'Atletico vs Sevilla 1X2', home: 'Atletico 1.90', draw: 'Draw 3.4', away: 'Sevilla 4.20' },
        { code:'0132', fixture: 'Porto vs Benfica 1X2', home: 'Porto 1.80', draw: 'Draw 3.6', away: 'Benfica 4.40' },
        { code:'0132', fixture: 'Ajax vs Feyenoord 1X2', home: 'Ajax 1.60', draw: 'Draw 4.2', away: 'Feyenoord 5.75' },
      ];
    
      const leagues = [
        { name: 'Premier League', code: '101' },
        { name: 'La Liga', code: '102' },
        { name: 'Bundesliga', code: '103' },
        { name: 'Serie A', code: '104' },
        { name: 'Ligue 1', code: '105' },
        { name: 'Major League Soccer', code: '106' },
        { name: 'Brasileirão Série A', code: '107' },
        { name: 'Eredivisie', code: '108' },
        { name: 'Primeira Liga', code: '109' },
        { name: 'Russian Premier League', code: '110' },
        { name: 'Super Lig', code: '111' },
        { name: 'Scottish Premiership', code: '112' },
        { name: 'Jupiler Pro League', code: '113' },
        { name: 'Superliga Argentina', code: '114' },
        { name: 'Liga MX', code: '115' },
        { name: 'Chinese Super League', code: '116' },
        { name: 'A-League', code: '117' },
        { name: 'K League 1', code: '118' },
        { name: 'J1 League', code: '119' },
        { name: 'Saudi Professional League', code: '120' }
      ];

  return (
        <div className={styles.mainContainer}>
            <div className={styles.subHeader}>
                <h4>England Premier League</h4>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button onClick={toggleSidebar} variant="outlined" style={{color:'white', backgroundColor:'#1a2035', border:'1px solid #1a2035'}}>Open BetSlips</Button>
                </div>
            </div>
            <div className={styles.parent}>
            <TableContainer component={Paper} className={styles.firstChild} style={{ maxHeight: 900 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.headingStyle} align="left">Code</TableCell>
                            <TableCell className={styles.headingStyle} align="left">League Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {leagues.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left" component="th" scope="row">
                                {row.code}
                            </TableCell>
                            <TableCell className={styles.paddingLeft} align="left" component="th" scope="row">
                                {/* <Link key={row.code} to={`/home/${row.code}`}> */}
                                    {row.name}
                                {/* </Link>   */}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TableContainer component={Paper} className={styles.secondChild} style={{ maxHeight: 900 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '7%' }} className={styles.headingStyle} align="left">Code</TableCell>
                            <TableCell style={{ width: '26%' }} className={styles.headingStyle} align="left">Fixture/Offer Type</TableCell>
                            <TableCell style={{ width: '17%' }} className={styles.headingStyle} align="center">Home</TableCell>
                            <TableCell style={{ width: '17%' }} className={styles.headingStyle} align="center">Draw</TableCell>
                            <TableCell style={{ width: '20%' }} className={styles.headingStyle} align="center">Away</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left" style={{ width: '7%' }} component="th" scope="row">
                                {row.code}
                            </TableCell>
                            <TableCell style={{ width: '26%' }} className={styles.paddingLeft} align="left" component="th" scope="row">
                                <Link key={row.code} to={`/home/${row.code}`}>
                                    <IconButton>
                                        <AddIcon/>
                                    </IconButton>
                                </Link>  
                                {row.fixture}
                            </TableCell>
                            <TableCell style={{ width: '17%' }} className={styles.paddingLeft} align="left">
                                <Checkbox /> {row.home} 
                            </TableCell>
                            <TableCell style={{ width: '17%' }} className={styles.paddingLeft} align="left">
                                <Checkbox /> {row.draw} 
                            </TableCell>
                            <TableCell style={{ width: '20%' }} className={styles.paddingLeft} align="left">
                                <Checkbox /> {row.away} 
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <RightSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

            </div>
        </div>
  );
};

export default MainContainer;
